
import Vue from "vue";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import {
  endorsementMapState,
  endorsementMapActions
} from "@/store/modules/endorsements";

interface IEndorsementActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "endorsementActivitiesView",
  mixins: [ActivitiesMixin],

  data(): IEndorsementActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  methods: {
    ...endorsementMapActions(["getEndorsement"])
  },
  computed: {
    ...endorsementMapState(["editing", "makingApiRequest"]),
    queryOverride(): any {
      return {
        name__in: `
          ADD_ENDORSEMENT_SUCCESS,
          ADD_ENDORSEMENT_FAILURE,
          UPDATE_ENDORSEMENT_SUCCESS,
          UPDATE_ENDORSEMENT_FAILURE,
          DELETE_ENDORSEMENT_SUCCESS,
          DELETE_ENDORSEMENT_FAILURE,
          ADD_BULK_ENDORSEMENT_SUCCESS,
          ADD_BULK_ENDORSEMENT_FAILURE,
          DELETE_BULK_ENDORSEMENT_SUCCESS,
          DELETE_BULK_ENDORSEMENT_FAILURE`.replace(/\s/g, ""),
        activityType: "EndorsementActivity"
      };
    }
  }
});
